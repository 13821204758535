<header>
    <h3 class="text-lg font-medium">Changelog</h3>
    <button btn icon mat-dialog-close>
        <app-icon>close</app-icon>
    </button>
</header>
<main class="overflow-auto p-4 max-w-[80vw] max-h-[65vh]">
    <div
        class="markdown items-start"
        *ngIf="changelog; else empty_state"
        [innerHTML]="changelog | safe:'html'"
    ></div>
</main>
<ng-template #empty_state>
    <div class="body">
        <div class="info-block">
            <app-icon>close</app-icon>
            <div class="text">No changelog</div>
        </div>
    </div>
</ng-template>
