<header>
    <h3>
        {{ item && edit ? 'Edit' : 'New' }}
        {{ name === 'Repositorie' ? 'Repository' : name }}
    </h3>
    <button btn icon mat-dialog-close *ngIf="!loading">
        <app-icon>close</app-icon>
    </button>
</header>
<main class="max-h-[65vh] overflow-auto p-4" *ngIf="!loading; else load_state">
    <ng-container [ngSwitch]="item_type">
        <ng-container *ngSwitchCase="'repository'">
            <repository-form [form]="form"></repository-form>
        </ng-container>
        <ng-container *ngSwitchCase="'trigger'">
            <trigger-form [form]="form"></trigger-form>
        </ng-container>
        <ng-container *ngSwitchCase="'system-trigger'">
            <system-trigger-form [form]="form"></system-trigger-form>
        </ng-container>
        <ng-container *ngSwitchCase="'application'">
            <application-form [form]="form"></application-form>
        </ng-container>
        <ng-container *ngSwitchCase="'domain'">
            <domain-form [form]="form"></domain-form>
        </ng-container>
        <ng-container *ngSwitchCase="'user'">
            <user-form [form]="form"></user-form>
        </ng-container>
        <ng-container *ngSwitchCase="'driver'">
            <driver-form
                [form]="form"
                (waiting)="can_submit = !$event"
            ></driver-form>
        </ng-container>
        <ng-container *ngSwitchCase="'zone'">
            <zone-form [form]="form"></zone-form>
        </ng-container>
        <ng-container *ngSwitchCase="'module'">
            <module-form [form]="form" [readonly]="readonly"></module-form>
        </ng-container>
        <ng-container *ngSwitchCase="'broker'">
            <broker-form [form]="form"></broker-form>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <system-form [form]="form"></system-form>
        </ng-container>
    </ng-container>
</main>
<footer
    class="border-t border-base-200 flex items-center justify-end space-x-2 p-4"
    *ngIf="!loading"
>
    <button btn class="inverse w-32" mat-dialog-close>Cancel</button>
    <button
        btn
        type="submit"
        class="w-32"
        [disabled]="!can_submit"
        (click)="submit()"
    >
        {{ item ? 'Save' : 'Add' }} <span class="keycap ml-2">S</span>
    </button>
</footer>
<ng-template #load_state>
    <div class="body">
        <div class="info-block">
            <div class="icon">
                <mat-spinner diameter="32"></mat-spinner>
            </div>
            <div class="text">Processing request...</div>
        </div>
    </div>
</ng-template>
