<div class="trigger-condition form comparison" *ngIf="form" [formGroup]="form">
    <ng-container
        *ngTemplateOutlet="status_variable_form; context: { side: 'left' }"
    ></ng-container>
    <div
        *ngIf="form.controls.left.touched && form.controls.left.errors"
        class="error"
        i18n="@@statusVarError"
    >
        A module status variable is required
    </div>
    <div class="field" *ngIf="form.controls.operator">
        <label for="operator" hidden i18n="@@operationLabel">Operation:</label>
        <mat-form-field appearance="outline">
            <mat-select
                name="operator"
                formControlName="operator"
                placeholder="Select comparison operator"
            >
                <mat-option
                    *ngFor="let operation of compare_types"
                    [value]="operation.id"
                >
                    {{ operation.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="field" *ngIf="form.controls.operator">
        <label for="compared-to" hidden i18n="@@comparedLabel"
            >Compared to:</label
        >
        <mat-form-field appearance="outline">
            <mat-select
                name="compared-to"
                [(ngModel)]="rhs_type"
                (ngModelChange)="form.controls.right.setValue(null)"
                [ngModelOptions]="{ standalone: true }"
            >
                <mat-option
                    *ngFor="let type of right_var_type"
                    [value]="type.id"
                >
                    {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div
        class="field"
        *ngIf="
            rhs_type === 'constant' && form.controls.right;
            else rhs_status_var
        "
    >
        <label for="constant" hidden i18n="@@comparedLabel">Compared to:</label>
        <mat-form-field appearance="outline">
            <input
                matInput
                name="constant"
                formControlName="right"
                placeholder="true/false, 'string', 123.456"
                i18n-placeholder="@@comparedValue"
            />
            <mat-error i18n="@@statusValueError"
                >Valid JSON is required</mat-error
            >
        </mat-form-field>
    </div>
</div>
<ng-template #rhs_status_var>
    <ng-container
        *ngTemplateOutlet="status_variable_form; context: { side: 'right' }"
    ></ng-container>
</ng-template>
<ng-template #status_variable_form let-side="side">
    <div class="fieldset">
        <div class="field" *ngIf="this[side + '_side']">
            <label for="type" i18n="@@moduleLabel">Module:</label>
            <mat-form-field appearance="outline">
                <mat-select
                    name="type"
                    [(ngModel)]="this[side + '_side'].mod"
                    (ngModelChange)="loadSystemStatusVariables($event, side)"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Select module"
                >
                    <mat-option
                        *ngFor="let mod of module_list"
                        [value]="mod.name"
                    >
                        {{ mod.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="field" *ngIf="this[side + '_status_variables']?.length">
            <label [for]="side + '-status-var'" i18n="@@statusVarLabel"
                >Status Variable:</label
            >
            <mat-form-field appearance="outline">
                <mat-select
                    [name]="side + '-status-var'"
                    [(ngModel)]="this[side + '_side'].status"
                    (ngModelChange)="updateFormForSide(side)"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="Select status variable"
                    i18n-placeholder="@@statusVarPlaceholder"
                >
                    <mat-option
                        *ngFor="let mod of this[side + '_status_variables']"
                        [value]="mod.name"
                    >
                        {{ mod.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div
        class="field"
        *ngIf="
            this[side + '_status_variables'] &&
            this[side + '_status_variables'].length
        "
    >
        <label [for]="side + '-subkeys'" i18n="@@subkeysLabel">Subkeys:</label>
        <mat-form-field appearance="outline">
            <input
                matInput
                [ngModel]="this[side + '_keys']"
                (ngModelChange)="
                    this[side + '_side'].keys = $event.split(',');
                    updateFormForSide(side)
                "
                [name]="side + '-subkeys'"
                placeholder="Status variable subkeys..."
                i18n-placeholder="@@subkeysPlaceholder"
            />
        </mat-form-field>
    </div>
</ng-template>
