<div class="trigger-condition form time" *ngIf="form" [formGroup]="form">
    <div class="field">
        <mat-checkbox
            [(ngModel)]="is_cron"
            (ngModelChange)="toggleCRON($event)"
            [ngModelOptions]="{ standalone: true }"
            i18n="@@recurringLabel"
        >
            Recurring Schedule
        </mat-checkbox>
    </div>
    <div class="field" *ngIf="is_cron">
        <label for="timezone">Timezone</label>
        <mat-form-field appearance="outline">
            <app-icon matPrefix class="text-2xl">search</app-icon>
            <input
                matInput
                formControlName="timezone"
                placeholder="System timezone"
                [matAutocomplete]="auto"
            />
        </mat-form-field>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let tz of timezones" [value]="tz">{{
                tz
            }}</mat-option>
            <mat-option *ngIf="!timezones.length" [disabled]="true"
                >No matching timezones</mat-option
            >
        </mat-autocomplete>
    </div>
    <div class="fieldset" *ngIf="!is_cron; else cron_time_form">
        <div class="field" *ngIf="form.controls.time">
            <label for="type" i18n="@@dateLabel">Date: </label>
            <a-date-field name="date" formControlName="time"></a-date-field>
        </div>
        <div class="field" *ngIf="form.controls.time">
            <label for="type" i18n="@@timeLabel">Time: </label>
            <a-time-field name="date" formControlName="time"></a-time-field>
        </div>
    </div>
</div>
<ng-template #cron_time_form>
    <div class="field">
        <label for="type" i18n="@@repeatLabel">Repeat every: </label>
        <mat-form-field appearance="outline">
            <mat-select
                name="type"
                [(ngModel)]="cron_period"
                (ngModelChange)="updateCronString()"
                [ngModelOptions]="{ standalone: true }"
            >
                <mat-option
                    *ngFor="let period of repeat_period"
                    [value]="period.id"
                >
                    {{ period.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div
        class="field"
        *ngIf="cron_period === 'month' || cron_period === 'year'"
    >
        <label for="day" i18n="@@dayLabel">Day of Month: </label>
        <mat-form-field appearance="outline">
            <mat-select
                name="day"
                [(ngModel)]="cron_date"
                (ngModelChange)="updateCronString()"
                [ngModelOptions]="{ standalone: true }"
            >
                <mat-option
                    *ngFor="let period of days_of_month"
                    [value]="period.id"
                >
                    {{ period.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="field" *ngIf="cron_period === 'year'">
        <label for="month" i18n="@@monthLabel">Month of year: </label>
        <mat-button-toggle-group
            name="month"
            [(ngModel)]="cron_month"
            (ngModelChange)="updateCronString()"
            [ngModelOptions]="{ standalone: true }"
        >
            <mat-button-toggle
                *ngFor="let month of months_of_year"
                [value]="month"
            >
                {{ month }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="field" *ngIf="cron_period === 'week'">
        <label for="weekday" i18n="@@weekLabel">Day of week: </label>
        <mat-button-toggle-group
            name="weekday"
            [(ngModel)]="cron_day"
            (ngModelChange)="updateCronString()"
            [ngModelOptions]="{ standalone: true }"
        >
            <mat-button-toggle
                *ngFor="let weekday of days_of_week"
                [value]="weekday"
            >
                {{ weekday }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div
        class="field"
        *ngIf="cron_period !== 'minute' && cron_period !== 'hour'"
    >
        <label for="hour" i18n="@@hourLabel">Hour in the day: </label>
        <mat-button-toggle-group
            name="hour"
            [(ngModel)]="cron_hour"
            (ngModelChange)="updateCronString()"
            [ngModelOptions]="{ standalone: true }"
        >
            <mat-button-toggle *ngFor="let hour of hours_in_day" [value]="hour">
                {{ hour }}
            </mat-button-toggle>
        </mat-button-toggle-group>
        <mat-button-toggle-group
            name="hour"
            [(ngModel)]="cron_hour_period"
            (ngModelChange)="updateCronString()"
            [ngModelOptions]="{ standalone: true }"
        >
            <mat-button-toggle *ngFor="let hour of ['AM', 'PM']" [value]="hour">
                {{ hour }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
    <div class="field" *ngIf="cron_period !== 'minute'">
        <label for="minute" i18n="@@minuteLabel">Minute past the hour: </label>
        <mat-button-toggle-group
            name="minute"
            [(ngModel)]="cron_minute"
            (ngModelChange)="updateCronString()"
            [ngModelOptions]="{ standalone: true }"
        >
            <mat-button-toggle
                *ngFor="let minute of minutes_in_hour"
                [value]="minute"
            >
                {{ minute }}
            </mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</ng-template>
