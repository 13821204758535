export const DISCOVERY = [
    {
        name: '3M Wall Display',
        role: 'device',
        description:
            '        Display control is via RS-232 only. Ensure IP -> RS-232 converter has\n        been configured to provide comms at 9600,N,8,1.\n',
        default: 4999,
        class_name: '::X3m::Displays::WallDisplay',
        module_name: 'Display',
        makebreak: null,
        settings: {},
        created_at: 1519091301,
        file_exists: true,
        id: 'disc-::X3m::Displays::WallDisplay',
    },
    {
        name: 'ACA Blind Control Logic',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::BlindLogic',
        module_name: 'Blinds',
        makebreak: false,
        settings: {},
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Aca::BlindLogic',
    },
    {
        name: 'ACA Demo Logic',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::DemoLogic',
        module_name: 'Demo',
        makebreak: null,
        settings: {},
        created_at: 1503273826,
        file_exists: true,
        id: 'disc-::Aca::DemoLogic',
    },
    {
        name: 'ACA Desk Management',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::Tracking::DeskManagement',
        module_name: 'DeskManagement',
        makebreak: null,
        settings: {
            mappings: { switch_ip: { port_id: 'desk_id' } },
            checkin: { level_id: [] },
            timezone: 'Singapore',
            desk_hold_time: 300,
            desk_reserve_time: 7200,
            manual_reserve_time: 7200,
            user_identifier: 'login_name',
        },
        created_at: 1519795318,
        file_exists: true,
        id: 'disc-::Aca::Tracking::DeskManagement',
    },
    {
        name: 'ACA Device Config Manager',
        role: 'logic',
        description:
            'Utility module for executing device setup actions when connectivity is\nestablished.\n\nActions may be specified under the `device_config` setting. This should\nbe of the form:\n\n    mod => { method => args }\n\nOr, if a method must be executed multiple times\n\n    mod => [{ method => args }]\n',
        default: null,
        class_name: '::Aca::DeviceConfig',
        module_name: 'DeviceConfig',
        makebreak: null,
        settings: { device_config: {} },
        created_at: 1541653138,
        file_exists: true,
        id: 'disc-::Aca::DeviceConfig',
    },
    {
        name: 'ACA Device Probe',
        role: 'device',
        description: 'Passthrough / comms logger for probing device protocols',
        default: null,
        class_name: '::Aca::DeviceProbe',
        module_name: 'Probe',
        makebreak: null,
        settings: { hex: false },
        created_at: 1519091301,
        file_exists: true,
        id: 'disc-::Aca::DeviceProbe',
    },
    {
        name: 'ACA Manual Lighting Logic',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::LightingManual',
        module_name: 'Lighting',
        makebreak: false,
        settings: { light_levels: [{ zones: [], level: 0 }] },
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Aca::LightingManual',
    },
    {
        name: 'ACA Meeting Room Logic',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::MeetingRoom',
        module_name: 'System',
        makebreak: false,
        settings: { joiner_driver: 'System' },
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Aca::MeetingRoom',
    },
    {
        name: 'ACA MyTurn Switching Logic',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::MyTurn',
        module_name: 'MyTurn',
        makebreak: false,
        settings: {},
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Aca::MyTurn',
    },
    {
        name: 'ACA PC Control Interface',
        role: 'device',
        description: null,
        default: 443,
        class_name: '::Aca::PcControl',
        module_name: 'Computer',
        makebreak: false,
        settings: { domain: '.', username: 'service', password: 'account' },
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Aca::PcControl',
    },
    {
        name: 'ACA People Count',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::Tracking::PeopleCounter',
        module_name: 'Count',
        makebreak: null,
        settings: {},
        created_at: 1548201724,
        file_exists: true,
        id: 'disc-::Aca::Tracking::PeopleCounter',
    },
    {
        name: 'ACA Room Joining Logic',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::Joiner',
        module_name: 'Joiner',
        makebreak: false,
        settings: {},
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Aca::Joiner',
    },
    {
        name: 'ACA Signal Router',
        role: 'logic',
        description:
            'Signal distribution management for handling routing across multiple\ndevices and complex/layered switching infrastructure.\n',
        default: null,
        class_name: '::Aca::Router',
        module_name: 'Router',
        makebreak: null,
        settings: { connections: {} },
        created_at: 1548201724,
        file_exists: true,
        id: 'disc-::Aca::Router',
    },
    {
        name: 'ACA Skype Logic',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::SkypeLogic',
        module_name: 'Skype',
        makebreak: false,
        settings: {},
        created_at: 1497402745,
        file_exists: true,
        id: 'disc-::Aca::SkypeLogic',
    },
    {
        name: 'ACA Streaming Recorder',
        role: 'service',
        description: null,
        default: null,
        class_name: '::Aca::Recorder',
        module_name: 'Recorder',
        makebreak: false,
        settings: {},
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Aca::Recorder',
    },
    {
        name: 'ACA Television Logic',
        role: 'logic',
        description: null,
        default: null,
        class_name: '::Aca::TelevisionLogic',
        module_name: 'Television',
        makebreak: false,
        settings: {},
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Aca::TelevisionLogic',
    },
    {
        name: 'AMX Acendo Vibe',
        role: 'device',
        description: null,
        default: 4999,
        class_name: '::Amx::AcendoVibe',
        module_name: 'Mixer',
        makebreak: null,
        settings: {},
        created_at: 1519795318,
        file_exists: true,
        id: 'disc-::Amx::AcendoVibe',
    },
    {
        name: 'AMX SVSI N-Series Decoder',
        role: 'device',
        description: null,
        default: 50002,
        class_name: '::Amx::Svsi::NSeriesDecoder',
        module_name: 'Decoder',
        makebreak: false,
        settings: {},
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Amx::Svsi::NSeriesDecoder',
    },
    {
        name: 'AMX SVSI N-Series Encoder',
        role: 'device',
        description: null,
        default: 50002,
        class_name: '::Amx::Svsi::NSeriesEncoder',
        module_name: 'Encoder',
        makebreak: false,
        settings: {},
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Amx::Svsi::NSeriesEncoder',
    },
    {
        name: 'AMX SVSI N-Series Switcher',
        role: 'device',
        description: null,
        default: 50020,
        class_name: '::Amx::Svsi::NSeriesSwitcher',
        module_name: 'Switcher',
        makebreak: false,
        settings: {},
        created_at: 1494775225,
        file_exists: true,
        id: 'disc-::Amx::Svsi::NSeriesSwitcher',
    },
];
