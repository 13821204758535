<header>
    <div class="text">{{ title || 'Exec Request Results' }}</div>
    <button btn icon mat-dialog-close>
        <app-icon>close</app-icon>
    </button>
</header>
<mat-dialog-content>
    <settings-form-field
        [ngModel]="content_string"
        [readonly]="true"
    ></settings-form-field>
</mat-dialog-content>
